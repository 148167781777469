@font-face {
  font-family: lg;
  src: url("lg.f8edcdbb.woff2") format("woff2"), url("lg.253cca28.ttf") format("truetype"), url("lg.f801b1a8.woff") format("woff"), url("lg.c9542252.svg#lg") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

.lg-icon {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  font-family: lg !important;
}

.lg-container {
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.lg-next, .lg-prev {
  color: #999;
  cursor: pointer;
  z-index: 1080;
  background-color: #00000073;
  border: none;
  border-radius: 2px;
  outline: 0;
  margin-top: -10px;
  padding: 8px 10px 9px;
  font-size: 22px;
  display: block;
  position: absolute;
  top: 50%;
}

.lg-next.disabled, .lg-prev.disabled {
  cursor: default;
  opacity: 0 !important;
}

.lg-next:hover:not(.disabled), .lg-prev:hover:not(.disabled) {
  color: #fff;
}

.lg-single-item .lg-next, .lg-single-item .lg-prev {
  display: none;
}

.lg-next {
  right: 20px;
}

.lg-next:before {
  content: "";
}

.lg-prev {
  left: 20px;
}

.lg-prev:after {
  content: "";
}

@keyframes lg-right-end {
  0% {
    left: 0;
  }

  50% {
    left: -30px;
  }

  100% {
    left: 0;
  }
}

@keyframes lg-left-end {
  0% {
    left: 0;
  }

  50% {
    left: 30px;
  }

  100% {
    left: 0;
  }
}

.lg-outer.lg-right-end .lg-object {
  animation: .3s lg-right-end;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  animation: .3s lg-left-end;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-media-overlap .lg-toolbar {
  background-image: linear-gradient(0deg, #0000, #0006);
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  text-align: center;
  will-change: color;
  -o-transition: color .2s linear;
  box-shadow: none;
  background: none;
  border: none;
  outline: none;
  width: 50px;
  height: 47px;
  padding: 10px 0;
  font-size: 24px;
  line-height: 27px;
  transition: color .2s linear;
  text-decoration: none !important;
}

.lg-toolbar .lg-icon.lg-icon-18 {
  font-size: 18px;
}

.lg-toolbar .lg-icon:hover {
  color: #fff;
}

.lg-toolbar .lg-close:after {
  content: "";
}

.lg-toolbar .lg-maximize {
  font-size: 22px;
}

.lg-toolbar .lg-maximize:after {
  content: "";
}

.lg-toolbar .lg-download:after {
  content: "";
}

.lg-sub-html {
  color: #eee;
  text-align: center;
  z-index: 1080;
  opacity: 0;
  -o-transition: opacity .2s ease-out 0s;
  padding: 10px 40px;
  font-size: 16px;
  transition: opacity .2s ease-out;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 700;
}

.lg-sub-html p {
  margin: 5px 0 0;
  font-size: 12px;
}

.lg-sub-html a {
  color: inherit;
}

.lg-sub-html a:hover {
  text-decoration: underline;
}

.lg-media-overlap .lg-sub-html {
  background-image: linear-gradient(#0000, #0009);
}

.lg-item .lg-sub-html {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.lg-error-msg {
  color: #999;
  font-size: 14px;
}

.lg-counter {
  color: #999;
  vertical-align: middle;
  height: 47px;
  padding-top: 12px;
  padding-left: 20px;
  font-size: 16px;
  display: inline-block;
}

.lg-closing .lg-next, .lg-closing .lg-prev, .lg-closing .lg-sub-html, .lg-closing .lg-toolbar {
  opacity: 0;
  -webkit-transition: -webkit-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  -moz-transition: -moz-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  -o-transition: -o-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  transition: transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap, body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
  opacity: 0;
  will-change: transform, opacity;
  transform: scale3d(.5, .5, .5);
  -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  transition: transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap, body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.lg-outer .lg-thumb-outer {
  float: left;
  background-color: #0d0a0a;
  width: 100%;
  max-height: 350px;
  overflow: hidden;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb, .lg-outer .lg-thumb-outer.lg-rebuilding-thumbnails .lg-thumb {
  transition-duration: 0s !important;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-middle {
  text-align: center;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-left {
  text-align: left;
}

.lg-outer .lg-thumb-outer.lg-thumb-align-right {
  text-align: right;
}

.lg-outer.lg-single-item .lg-thumb-outer {
  display: none;
}

.lg-outer .lg-thumb {
  vertical-align: middle;
  height: 100%;
  margin-bottom: -5px;
  padding: 5px 0;
  display: inline-block;
}

@media (width >= 768px) {
  .lg-outer .lg-thumb {
    padding: 10px 0;
  }
}

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  will-change: border-color;
  border-radius: 2px;
  height: 100%;
  margin-bottom: 5px;
  overflow: hidden;
}

@media (width >= 768px) {
  .lg-outer .lg-thumb-item {
    -o-transition: border-color .25s ease;
    border: 2px solid #fff;
    border-radius: 4px;
    transition: border-color .25s;
  }
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer .lg-toggle-thumb:after {
  content: "";
}

.lg-outer.lg-animate-thumb .lg-thumb {
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
}

.lg-outer .lg-video-cont {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.lg-outer .lg-video-cont .lg-object {
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-has-iframe .lg-video-cont {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.lg-outer .lg-video-object {
  z-index: 3;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.lg-outer .lg-video-poster {
  z-index: 1;
}

.lg-outer .lg-has-video .lg-video-object {
  opacity: 0;
  will-change: opacity;
  -o-transition: opacity .3s ease-in;
  transition: opacity .3s ease-in;
}

.lg-outer .lg-has-video.lg-video-loaded .lg-video-play-button, .lg-outer .lg-has-video.lg-video-loaded .lg-video-poster {
  opacity: 0 !important;
}

.lg-outer .lg-has-video.lg-video-loaded .lg-video-object {
  opacity: 1;
}

@keyframes lg-play-stroke {
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes lg-play-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.lg-video-play-button {
  z-index: 2;
  cursor: pointer;
  will-change: opacity, transform;
  -o-transition: -o-transform .25s cubic-bezier(.17, .88, .32, 1.28), opacity .1s;
  -webkit-transition: -webkit-transform .25s cubic-bezier(.17, .88, .32, 1.28), opacity .1s;
  -moz-transition: -moz-transform .25s cubic-bezier(.17, .88, .32, 1.28), opacity .1s;
  width: 18%;
  max-width: 140px;
  transition: transform .25s cubic-bezier(.17, .88, .32, 1.28), opacity .1s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

.lg-video-play-button:hover .lg-video-play-icon, .lg-video-play-button:hover .lg-video-play-icon-bg {
  opacity: 1;
}

.lg-video-play-icon-bg {
  fill: none;
  stroke-width: 3%;
  stroke: #fcfcfc;
  opacity: .6;
  will-change: opacity;
  -o-transition: opacity .12s ease-in;
  transition: opacity .12s ease-in;
}

.lg-video-play-icon-circle {
  fill: none;
  stroke-width: 3%;
  stroke: #1e1e1ee6;
  stroke-opacity: 1;
  stroke-linecap: round;
  stroke-dasharray: 200;
  stroke-dashoffset: 200px;
  position: absolute;
  inset: 0;
}

.lg-video-play-icon {
  opacity: .6;
  will-change: opacity;
  -o-transition: opacity .12s ease-in;
  width: 25%;
  max-width: 120px;
  transition: opacity .12s ease-in;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.lg-video-play-icon .lg-video-play-icon-inner {
  fill: #fcfcfc;
}

.lg-video-loading .lg-video-play-icon-circle {
  animation: 2s linear .25s infinite lg-play-rotate, 1.5s ease-in-out .25s infinite lg-play-stroke;
}

.lg-video-loaded .lg-video-play-button {
  opacity: 0;
  transform: translate(-50%, -50%)scale(.7);
}

.lg-progress-bar {
  z-index: 1083;
  opacity: 0;
  will-change: opacity;
  -o-transition: opacity 80ms ease 0s;
  background-color: #333;
  width: 100%;
  height: 5px;
  transition: opacity 80ms;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  width: 0;
  height: 5px;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "";
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: "";
}

.lg-single-item .lg-autoplay-button {
  opacity: .75;
  pointer-events: none;
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  transition-duration: 0s !important;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -o-transition: -o-transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s;
  -webkit-transition: -webkit-transform .5s cubic-bezier(.12, .415, .01, 1.19);
  -moz-transition: -moz-transform .5s cubic-bezier(.12, .415, .01, 1.19);
  transition: transform .5s cubic-bezier(.12, .415, .01, 1.19);
}

.lg-outer.lg-use-transition-for-zoom.lg-zoom-drag-transition .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -o-transition: -o-transform .8s cubic-bezier(0, 0, .25, 1) 0s;
  -webkit-transition: -webkit-transform .8s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .8s cubic-bezier(0, 0, .25, 1);
  transition: transform .8s cubic-bezier(0, 0, .25, 1);
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img, .lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  will-change: opacity, transform;
  transform-origin: 0 0;
  backface-visibility: hidden;
  transform: scale3d(1, 1, 1);
  -o-transition: -o-transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s, opacity .15s !important;
  -webkit-transition: -webkit-transform .5s cubic-bezier(.12, .415, .01, 1.19), opacity .15s !important;
  -moz-transition: -moz-transform .5s cubic-bezier(.12, .415, .01, 1.19), opacity .15s !important;
  transition: transform .5s cubic-bezier(.12, .415, .01, 1.19), opacity .15s !important;
}

.lg-icon.lg-zoom-in:after {
  content: "";
}

.lg-icon.lg-actual-size {
  font-size: 20px;
}

.lg-icon.lg-actual-size:after {
  content: "";
}

.lg-icon.lg-zoom-out {
  opacity: .5;
  pointer-events: none;
}

.lg-icon.lg-zoom-out:after {
  content: "";
}

.lg-zoomed .lg-icon.lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer.lg-first-slide-loading .lg-actual-size, .lg-outer.lg-first-slide-loading .lg-zoom-in, .lg-outer.lg-first-slide-loading .lg-zoom-out, .lg-outer[data-lg-slide-type="iframe"] .lg-actual-size, .lg-outer[data-lg-slide-type="iframe"] .lg-zoom-in, .lg-outer[data-lg-slide-type="iframe"] .lg-zoom-out, .lg-outer[data-lg-slide-type="video"] .lg-actual-size, .lg-outer[data-lg-slide-type="video"] .lg-zoom-in, .lg-outer[data-lg-slide-type="video"] .lg-zoom-out {
  opacity: .75;
  pointer-events: none;
}

.lg-outer .lg-pager-outer {
  text-align: center;
  z-index: 1080;
  height: 10px;
  margin-bottom: 10px;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer.lg-single-item .lg-pager-outer {
  display: none;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  vertical-align: top;
  margin: 0 5px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: inset 0 0 0 2px #fff;
}

.lg-outer .lg-pager-thumb-cont {
  color: #fff;
  opacity: 0;
  will-change: transform, opacity;
  -o-transition: opacity .15s ease 0s, -o-transform .15s ease 0s;
  -webkit-transition: opacity .15s, -webkit-transform .15s;
  -moz-transition: opacity .15s, -moz-transform .15s;
  background-color: #fff;
  border-radius: 3px;
  width: 120px;
  height: 83px;
  margin-bottom: 20px;
  margin-left: -60px;
  padding: 5px;
  transition: opacity .15s, transform .15s;
  position: absolute;
  bottom: 100%;
  left: 0;
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}

.lg-outer .lg-pager {
  -o-transition: box-shadow .3s ease 0s;
  background-color: #ffffff80;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow .3s;
  display: block;
  box-shadow: inset 0 0 0 8px #ffffffb3;
}

.lg-outer .lg-pager:focus, .lg-outer .lg-pager:hover {
  box-shadow: inset 0 0 0 8px #fff;
}

.lg-outer .lg-caret {
  vertical-align: middle;
  border-top: 10px dashed;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  width: 0;
  height: 0;
  margin-left: -5px;
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 50%;
}

.lg-fullscreen:after {
  content: "";
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: "";
}

.lg-outer .lg-dropdown-overlay {
  cursor: default;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  will-change: visibility, opacity;
  -o-transition: visibility 0s linear .18s, opacity .18s linear 0s;
  background-color: #00000040;
  transition: visibility 0s linear .18s, opacity .18s linear;
  position: absolute;
  inset: 0;
}

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active .lg-dropdown-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
  transform: translate3d(0, 0, 0);
}

.lg-outer.lg-dropdown-active .lg-share {
  color: #fff;
}

.lg-outer .lg-dropdown {
  text-align: left;
  opacity: 0;
  visibility: hidden;
  will-change: visibility, opacity, transform;
  -o-transition: -o-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
  -webkit-transition: -webkit-transform .18s linear, visibility 0s linear .5s, opacity .18s linear;
  -moz-transition: -moz-transform .18s linear, visibility 0s linear .5s, opacity .18s linear;
  background-color: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 10px 0;
  font-size: 14px;
  list-style-type: none;
  transition: transform .18s linear, visibility 0s linear .5s, opacity .18s linear;
  position: absolute;
  top: 50px;
  right: 0;
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-dropdown:after {
  content: "";
  border: 8px solid #0000;
  border-bottom-color: #fff;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: -16px;
  right: 16px;
}

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0;
}

.lg-outer .lg-dropdown > li:hover a {
  color: #333;
}

.lg-outer .lg-dropdown a {
  color: #333;
  white-space: pre;
  padding: 4px 12px;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  display: block;
}

.lg-outer .lg-dropdown a:hover {
  background-color: #00000012;
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  vertical-align: middle;
  margin-top: -3px;
  line-height: 1;
  display: inline-block;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  float: none;
  vertical-align: middle;
  width: auto;
  height: auto;
  margin-right: 8px;
  padding: 0;
  font-size: 20px;
  line-height: 1;
  display: inline-block;
}

.lg-outer .lg-share {
  position: relative;
}

.lg-outer .lg-share:after {
  content: "";
}

.lg-outer .lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer .lg-share-facebook .lg-icon:after {
  content: "";
}

.lg-outer .lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer .lg-share-twitter .lg-icon:after {
  content: "";
}

.lg-outer .lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer .lg-share-pinterest .lg-icon:after {
  content: "";
}

.lg-comment-box {
  z-index: 9999;
  will-change: transform;
  -o-transition: -o-transform .4s cubic-bezier(0, 0, .25, 1) 0s;
  -webkit-transition: -webkit-transform .4s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .4s cubic-bezier(0, 0, .25, 1);
  background-color: #fff;
  width: 420px;
  max-width: 100%;
  transition: transform .4s cubic-bezier(0, 0, .25, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translate3d(100%, 0, 0);
}

.lg-comment-box .lg-comment-title {
  color: #fff;
  margin: 0;
  font-size: 18px;
}

.lg-comment-box .lg-comment-header {
  background-color: #000;
  padding: 12px 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.lg-comment-box .lg-comment-body {
  width: 100% !important;
  height: 100% !important;
  padding-top: 43px !important;
}

.lg-comment-box .fb-comments {
  background: #fff url("loading.9e763859.gif") center no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow-y: auto;
}

.lg-comment-box .fb-comments[fb-xfbml-state="rendered"] {
  background-image: none;
}

.lg-comment-box .fb-comments > span {
  max-width: 100%;
}

.lg-comment-box .lg-comment-close {
  cursor: pointer;
  color: #999;
  will-change: color;
  -o-transition: color .2s linear;
  font-size: 20px;
  transition: color .2s linear;
  position: absolute;
  top: 12px;
  right: 5px;
}

.lg-comment-box .lg-comment-close:hover {
  color: #fff;
}

.lg-comment-box .lg-comment-close:after {
  content: "";
}

.lg-comment-box iframe {
  width: 100% !important;
  max-width: 100% !important;
}

.lg-comment-box #disqus_thread {
  padding: 0 20px;
}

.lg-outer .lg-comment-overlay {
  cursor: default;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  will-change: visibility, opacity;
  -o-transition: visibility 0s linear .18s, opacity .18s linear 0s;
  background-color: #00000040;
  transition: visibility 0s linear .18s, opacity .18s linear;
  position: fixed;
  inset: 0;
}

.lg-outer .lg-comment-toggle:after {
  content: "";
}

.lg-outer.lg-comment-active .lg-comment-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
  transform: translate3d(0, 0, 0);
}

.lg-outer.lg-comment-active .lg-comment-toggle {
  color: #fff;
}

.lg-outer.lg-comment-active .lg-comment-box {
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-img-rotate {
  -o-transition: -o-transform .4s cubic-bezier(0, 0, .25, 1) 0s;
  -webkit-transition: -webkit-transform .4s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .4s cubic-bezier(0, 0, .25, 1);
  transition: transform .4s cubic-bezier(0, 0, .25, 1);
  position: absolute;
  inset: 0;
}

.lg-outer[data-lg-slide-type="iframe"] .lg-flip-hor, .lg-outer[data-lg-slide-type="iframe"] .lg-flip-ver, .lg-outer[data-lg-slide-type="iframe"] .lg-rotate-left, .lg-outer[data-lg-slide-type="iframe"] .lg-rotate-right, .lg-outer[data-lg-slide-type="video"] .lg-flip-hor, .lg-outer[data-lg-slide-type="video"] .lg-flip-ver, .lg-outer[data-lg-slide-type="video"] .lg-rotate-left, .lg-outer[data-lg-slide-type="video"] .lg-rotate-right {
  opacity: .75;
  pointer-events: none;
}

.lg-rotate-left:after {
  content: "";
}

.lg-rotate-right:after {
  content: "";
}

.lg-icon.lg-flip-hor, .lg-icon.lg-flip-ver {
  font-size: 26px;
}

.lg-flip-ver:after {
  content: "";
}

.lg-flip-hor:after {
  content: "";
}

.lg-medium-zoom-item {
  cursor: zoom-in;
}

.lg-medium-zoom .lg-outer, .lg-medium-zoom .lg-outer.lg-grab img.lg-object, .lg-medium-zoom .lg-outer.lg-grabbing img.lg-object {
  cursor: zoom-out;
}

.lg-relative-caption .lg-outer .lg-sub-html {
  white-space: normal;
  background-image: none;
  padding: 0;
  bottom: auto;
}

.lg-relative-caption .lg-outer .lg-relative-caption-item {
  opacity: 0;
  padding: 16px 0;
  transition: opacity .5s;
}

.lg-relative-caption .lg-outer .lg-show-caption .lg-relative-caption-item {
  opacity: 1;
}

.lg-group:after {
  content: "";
  clear: both;
  display: table;
}

.lg-container {
  outline: 0;
  display: none;
}

.lg-on {
  scroll-behavior: unset;
}

.lg-hide-sub-html .lg-sub-html, .lg-next, .lg-pager-outer, .lg-prev, .lg-toolbar {
  opacity: 0;
  will-change: transform, opacity;
  -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) 0s;
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1);
}

.lg-show-in .lg-next, .lg-show-in .lg-pager-outer, .lg-show-in .lg-prev, .lg-show-in .lg-toolbar, .lg-show-in.lg-hide-sub-html .lg-sub-html {
  opacity: 1;
}

.lg-show-in .lg-hide-items .lg-prev {
  opacity: 0;
  transform: translate3d(-10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-next {
  opacity: 0;
  transform: translate3d(10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-toolbar {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}

.lg-show-in .lg-hide-items.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}

.lg-outer {
  z-index: 1050;
  text-align: left;
  opacity: .001;
  will-change: auto;
  -o-transition: opacity .15s ease 0s;
  outline: 0;
  width: 100%;
  height: 100%;
  transition: opacity .15s;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.lg-outer * {
  box-sizing: border-box;
}

.lg-outer.lg-zoom-from-image, .lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
  opacity: 1;
  transition-duration: 0s !important;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-content {
  position: absolute;
  inset: 0;
}

.lg-outer .lg-inner {
  -o-transition: opacity 0s;
  white-space: nowrap;
  width: 100%;
  transition: opacity;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.lg-outer .lg-item {
  display: none !important;
}

.lg-outer .lg-item:not(.lg-start-end-progress) {
  background: url("loading.9e763859.gif") center no-repeat;
}

.lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide, .lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css .lg-current {
  display: inline-block !important;
}

.lg-outer .lg-img-wrap, .lg-outer .lg-item {
  text-align: center;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
}

.lg-outer .lg-img-wrap:before, .lg-outer .lg-item:before {
  content: "";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.lg-outer .lg-img-wrap {
  white-space: nowrap;
  font-size: 0;
  position: absolute;
  inset: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-object {
  vertical-align: middle;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: inline-block;
  position: relative;
}

.lg-outer .lg-empty-html .lg-sub-html, .lg-outer .lg-empty-html.lg-sub-html {
  display: none;
}

.lg-outer.lg-hide-download .lg-download {
  opacity: .75;
  pointer-events: none;
}

.lg-outer .lg-first-slide .lg-dummy-img {
  position: absolute;
  top: 50%;
  left: 50%;
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1;
  transition: opacity .2s ease-out .15s;
}

.lg-backdrop {
  z-index: 1040;
  opacity: 0;
  will-change: auto;
  -o-transition: opacity .333s ease-in 0s;
  background-color: #000;
  transition: opacity .333s ease-in;
  position: fixed;
  inset: 0;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-current, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-prev-slide {
  -o-transition: none 0s ease 0s !important;
  transition: none !important;
}

.lg-css3.lg-use-css3 .lg-item {
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-current, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-prev-slide {
  -o-transition: opacity .1s ease 0s;
  transition: opacity .1s;
}

.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
  -o-transition: -o-transform 1s cubic-bezier(.175, .885, .32, 1.275) 0s;
  -webkit-transition: -webkit-transform 1s cubic-bezier(.175, .885, .32, 1.275);
  -moz-transition: -moz-transform 1s cubic-bezier(.175, .885, .32, 1.275);
  transition: transform 1s cubic-bezier(.175, .885, .32, 1.275);
}

.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
  -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s;
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1);
  transition: transform 1s cubic-bezier(0, 0, .25, 1);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
  transition: transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
}

.lg-container {
  display: none;
}

.lg-container.lg-show {
  display: block;
}

.lg-container.lg-dragging-vertical .lg-backdrop {
  transition-duration: 0s !important;
}

.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
  opacity: 1;
  transition-duration: 0s !important;
}

.lg-inline .lg-backdrop, .lg-inline .lg-outer {
  position: absolute;
}

.lg-inline .lg-backdrop {
  z-index: 1;
}

.lg-inline .lg-outer {
  z-index: 2;
}

.lg-inline .lg-maximize:after {
  content: "";
}

.lg-components {
  will-change: transform;
  -o-transition: -o-transform .35s ease-out 0s;
  -webkit-transition: -webkit-transform .35s ease-out;
  z-index: 1080;
  -moz-transition: -moz-transform .35s ease-out;
  transition: transform .35s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate3d(0, 100%, 0);
}
/*# sourceMappingURL=index.2dda878c.css.map */
